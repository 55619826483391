import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from "@material-ui/core/FormHelperText";

const DropDownCustomised = ({ label, values, selectedValue, setSelectedValue, error, helperText }) => {
    return (
        <FormControl error={error} disabled={!(values && values.length)} required fullWidth variant="filled" margin="normal">
            <InputLabel shrink>{label}</InputLabel>
            <Select name={label} value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)}>
                <MenuItem key="" value="" disabled>
                    Select {label}
                </MenuItem>
                {
                    values && values.map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)
                }
            </Select>
            {
                helperText !== "" ?
                <FormHelperText>{helperText}</FormHelperText> :
                null
            }
        </FormControl>
    );
};

export default DropDownCustomised;
