const locations = [
    {
        "service": "Bulletproof Bundle",
        "location": "Online",
        "batch": "Bundle #1"
    },
    {
        "service": "Bulletproof Bundle",
        "location": "Online",
        "batch": "Bundle #2"
    },
    {
        "service": "Bulletproof Bundle",
        "location": "Online",
        "batch": "Bundle #3"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "MWF 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "MWF 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "MWF 7:15AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "TRS 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "TRS 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Morning",
        "batch": "TRS 7:15AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "MWF 5PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "MWF 6PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "MWF 7:15PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "TRS 5PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "TRS 6PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Virtual - Evening",
        "batch": "TRS 7:15PM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Nungambakkam",
        "batch": "MWF 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Nungambakkam",
        "batch": "MWF 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Nungambakkam",
        "batch": "MWF 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "MWF 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "MWF 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "MWF 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "TRS 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "TRS 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Chaos",
        "batch": "TRS 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "MWF 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "MWF 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "MWF 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "TRS 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "TRS 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Teynampet",
        "batch": "TRS 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "MWF 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "MWF 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "MWF 7AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "TRS 5AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "TRS 6AM"
    },
    {
        "service": "The Quad's BootCamp",
        "location": "Velachery",
        "batch": "TRS 7AM"
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Lite",
        "location": "Online",
        "batch": ""
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Value",
        "location": "Online",
        "batch": ""
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Pro",
        "location": "Online",
        "batch": ""
    },
    {
        "service": "Physical Therapy",
        "location": "Quad HQ",
        "batch": ""
    }
];
const durations = [
    {
        "service": "Bulletproof Bundle",
        "duration": "10 Weeks",
        "paymentOption": "Pay in full",
        "amountInINR": "25000"
    },
    {
        "service": "Bulletproof Bundle",
        "duration": "10 Weeks",
        "paymentOption": "Pay a custom amount",
        "amountInINR": ""
    },
    // {
    //     "service": "The Quad's BootCamp",
    //     "duration": "1 Month",
    //     "paymentOption": "Pay in full",
    //     "amountInINR": "7080",
    // },
    // {
    //     "service": "The Quad's BootCamp",
    //     "duration": "1 Month",
    //     "paymentOption": "Pay a custom amount",
    //     "amountInINR": "",
    // },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "6 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "40120",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "6 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "6 Months",
        "paymentOption": "Pay installment 1",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "6 Months",
        "paymentOption": "Pay installment 2",
        "amountInINR": "20060",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "9 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "59000",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "9 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "9 Months",
        "paymentOption": "Pay installment 1",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "9 Months",
        "paymentOption": "Pay installment 2",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "9 Months",
        "paymentOption": "Pay installment 3",
        "amountInINR": "18880",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "70800",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay installment 1",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay installment 2",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay installment 3",
        "amountInINR": "21240",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "12 Months",
        "paymentOption": "Pay installment 4",
        "amountInINR": "14160",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Lite",
        "duration": "10 Weeks",
        "paymentOption": "Pay in full",
        "amountInINR": "11800",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Lite",
        "duration": "10 Weeks",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Value",
        "duration": "4 Weeks",
        "paymentOption": "Pay in full",
        "amountInINR": "9440",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Value",
        "duration": "4 Weeks",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Value",
        "duration": "10 Weeks",
        "paymentOption": "Pay in full",
        "amountInINR": "21240",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Value",
        "duration": "10 Weeks",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Pro",
        "duration": "10 Weeks",
        "paymentOption": "Pay in full",
        "amountInINR": "53100",
    },
    {
        "service": "QuadVirtual(QVR) - 1on1 - Pro",
        "duration": "10 Weeks",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "Physical Therapy",
        "duration": "1 Month",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "24780",
        "location": "Nungambakkam"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
        "location": "Nungambakkam"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "24780",
        "location": "Chaos"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
        "location": "Chaos"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "24780",
        "location": "Velachery"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
        "location": "Velachery"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay in full",
        "amountInINR": "24780",
        "location": "Teynampet"
    },
    {
        "service": "The Quad's BootCamp",
        "duration": "3 Months",
        "paymentOption": "Pay a custom amount",
        "amountInINR": "",
        "location": "Teynampet"
    }
];
const countryNamesAndCallingCodes = [
    { "name": "Afghanistan", "callingCode": "+93" },
    { "name": "Albania", "callingCode": "+355" },
    { "name": "Algeria", "callingCode": "+213" },
    { "name": "American Samoa", "callingCode": "+1684" },
    { "name": "Andorra", "callingCode": "+376" },
    { "name": "Angola", "callingCode": "+244" },
    { "name": "Anguilla", "callingCode": "+1264" },
    { "name": "Antarctica", "callingCode": "+672" },
    { "name": "Antigua and Barbuda", "callingCode": "+1268" },
    { "name": "Argentina", "callingCode": "+54" },
    { "name": "Armenia", "callingCode": "+374" },
    { "name": "Aruba", "callingCode": "+297" },
    { "name": "Australia", "callingCode": "+61" },
    { "name": "Austria", "callingCode": "+43" },
    { "name": "Azerbaijan", "callingCode": "+994" },
    { "name": "Bahamas", "callingCode": "+1242" },
    { "name": "Bahrain", "callingCode": "+973" },
    { "name": "Bangladesh", "callingCode": "+880" },
    { "name": "Barbados", "callingCode": "+1246" },
    { "name": "Belarus", "callingCode": "+375" },
    { "name": "Belgium", "callingCode": "+32" },
    { "name": "Belize", "callingCode": "+501" },
    { "name": "Benin", "callingCode": "+229" },
    { "name": "Bermuda", "callingCode": "+1441" },
    { "name": "Bhutan", "callingCode": "+975" },
    { "name": "Bolivia", "callingCode": "+591" },
    { "name": "Bosnia and Herzegovina", "callingCode": "+387" },
    { "name": "Botswana", "callingCode": "+267" },
    { "name": "Brazil", "callingCode": "+55" },
    { "name": "British Indian Ocean Territory", "callingCode": "+246" },
    { "name": "British Virgin Islands", "callingCode": "+1284" },
    { "name": "Brunei", "callingCode": "+673" },
    { "name": "Bulgaria", "callingCode": "+359" },
    { "name": "Burkina Faso", "callingCode": "+226" },
    { "name": "Burundi", "callingCode": "+257" },
    { "name": "Cambodia", "callingCode": "+855" },
    { "name": "Cameroon", "callingCode": "+237" },
    { "name": "Canada", "callingCode": "+1" },
    { "name": "Cape Verde", "callingCode": "+238" },
    { "name": "Cayman Islands", "callingCode": "+1345" },
    { "name": "Central African Republic", "callingCode": "+236" },
    { "name": "Chad", "callingCode": "+235" },
    { "name": "Chile", "callingCode": "+56" },
    { "name": "China", "callingCode": "+86" },
    { "name": "Christmas Island", "callingCode": "+61" },
    { "name": "Cocos Islands", "callingCode": "+61" },
    { "name": "Colombia", "callingCode": "+57" },
    { "name": "Comoros", "callingCode": "+269" },
    { "name": "Cook Islands", "callingCode": "+682" },
    { "name": "Costa Rica", "callingCode": "+506" },
    { "name": "Croatia", "callingCode": "+385" },
    { "name": "Cuba", "callingCode": "+53" },
    { "name": "Curacao", "callingCode": "+599" },
    { "name": "Cyprus", "callingCode": "+357" },
    { "name": "Czech Republic", "callingCode": "+420" },
    { "name": "Democratic Republic of the Congo", "callingCode": "+243" },
    { "name": "Denmark", "callingCode": "+45" },
    { "name": "Djibouti", "callingCode": "+253" },
    { "name": "Dominica", "callingCode": "+1767" },
    { "name": "Dominican Republic", "callingCode": "+1809" },
    { "name": "East Timor", "callingCode": "+670" },
    { "name": "Ecuador", "callingCode": "+593" },
    { "name": "Egypt", "callingCode": "+20" },
    { "name": "El Salvador", "callingCode": "+503" },
    { "name": "Equatorial Guinea", "callingCode": "+240" },
    { "name": "Eritrea", "callingCode": "+291" },
    { "name": "Estonia", "callingCode": "+372" },
    { "name": "Ethiopia", "callingCode": "+251" },
    { "name": "Falkland Islands", "callingCode": "+500" },
    { "name": "Faroe Islands", "callingCode": "+298" },
    { "name": "Fiji", "callingCode": "+679" },
    { "name": "Finland", "callingCode": "+358" },
    { "name": "France", "callingCode": "+33" },
    { "name": "French Polynesia", "callingCode": "+689" },
    { "name": "Gabon", "callingCode": "+241" },
    { "name": "Gambia", "callingCode": "+220" },
    { "name": "Georgia", "callingCode": "+995" },
    { "name": "Germany", "callingCode": "+49" },
    { "name": "Ghana", "callingCode": "+233" },
    { "name": "Gibraltar", "callingCode": "+350" },
    { "name": "Greece", "callingCode": "+30" },
    { "name": "Greenland", "callingCode": "+299" },
    { "name": "Grenada", "callingCode": "+1473" },
    { "name": "Guam", "callingCode": "+1671" },
    { "name": "Guatemala", "callingCode": "+502" },
    { "name": "Guernsey", "callingCode": "+441481" },
    { "name": "Guinea", "callingCode": "+224" },
    { "name": "Guinea " },
    { "name": "Guyana", "callingCode": "+592" },
    { "name": "Haiti", "callingCode": "+509" },
    { "name": "Honduras", "callingCode": "+504" },
    { "name": "Hong Kong", "callingCode": "+852" },
    { "name": "Hungary", "callingCode": "+36" },
    { "name": "Iceland", "callingCode": "+354" },
    { "name": "India", "callingCode": "+91" },
    { "name": "Indonesia", "callingCode": "+62" },
    { "name": "Iran", "callingCode": "+98" },
    { "name": "Iraq", "callingCode": "+964" },
    { "name": "Ireland", "callingCode": "+353" },
    { "name": "Isle of Man", "callingCode": "+441624" },
    { "name": "Israel", "callingCode": "+972" },
    { "name": "Italy", "callingCode": "+39" },
    { "name": "Ivory Coast", "callingCode": "+225" },
    { "name": "Jamaica", "callingCode": "+1876" },
    { "name": "Japan", "callingCode": "+81" },
    { "name": "Jersey", "callingCode": "+441534" },
    { "name": "Jordan", "callingCode": "+962" },
    { "name": "Kazakhstan", "callingCode": "+7" },
    { "name": "Kenya", "callingCode": "+254" },
    { "name": "Kiribati", "callingCode": "+686" },
    { "name": "Kosovo", "callingCode": "+383" },
    { "name": "Kuwait", "callingCode": "+965" },
    { "name": "Kyrgyzstan", "callingCode": "+996" },
    { "name": "Laos", "callingCode": "+856" },
    { "name": "Latvia", "callingCode": "+371" },
    { "name": "Lebanon", "callingCode": "+961" },
    { "name": "Lesotho", "callingCode": "+266" },
    { "name": "Liberia", "callingCode": "+231" },
    { "name": "Libya", "callingCode": "+218" },
    { "name": "Liechtenstein", "callingCode": "+423" },
    { "name": "Lithuania", "callingCode": "+370" },
    { "name": "Luxembourg", "callingCode": "+352" },
    { "name": "Macau", "callingCode": "+853" },
    { "name": "Macedonia", "callingCode": "+389" },
    { "name": "Madagascar", "callingCode": "+261" },
    { "name": "Malawi", "callingCode": "+265" },
    { "name": "Malaysia", "callingCode": "+60" },
    { "name": "Maldives", "callingCode": "+960" },
    { "name": "Mali", "callingCode": "+223" },
    { "name": "Malta", "callingCode": "+356" },
    { "name": "Marshall Islands", "callingCode": "+692" },
    { "name": "Mauritania", "callingCode": "+222" },
    { "name": "Mauritius", "callingCode": "+230" },
    { "name": "Mayotte", "callingCode": "+262" },
    { "name": "Mexico", "callingCode": "+52" },
    { "name": "Micronesia", "callingCode": "+691" },
    { "name": "Moldova", "callingCode": "+373" },
    { "name": "Monaco", "callingCode": "+377" },
    { "name": "Mongolia", "callingCode": "+976" },
    { "name": "Montenegro", "callingCode": "+382" },
    { "name": "Montserrat", "callingCode": "+1664" },
    { "name": "Morocco", "callingCode": "+212" },
    { "name": "Mozambique", "callingCode": "+258" },
    { "name": "Myanmar", "callingCode": "+95" },
    { "name": "Namibia", "callingCode": "+264" },
    { "name": "Nauru", "callingCode": "+674" },
    { "name": "Nepal", "callingCode": "+977" },
    { "name": "Netherlands", "callingCode": "+31" },
    { "name": "Netherlands Antilles", "callingCode": "+599" },
    { "name": "New Caledonia", "callingCode": "+687" },
    { "name": "New Zealand", "callingCode": "+64" },
    { "name": "Nicaragua", "callingCode": "+505" },
    { "name": "Niger", "callingCode": "+227" },
    { "name": "Nigeria", "callingCode": "+234" },
    { "name": "Niue", "callingCode": "+683" },
    { "name": "North Korea", "callingCode": "+850" },
    { "name": "Northern Mariana Islands", "callingCode": "+1670" },
    { "name": "Norway", "callingCode": "+47" },
    { "name": "Oman", "callingCode": "+968" },
    { "name": "Pakistan", "callingCode": "+92" },
    { "name": "Palau", "callingCode": "+680" },
    { "name": "Palestine", "callingCode": "+970" },
    { "name": "Panama", "callingCode": "+507" },
    { "name": "Papua New Guinea", "callingCode": "+675" },
    { "name": "Paraguay", "callingCode": "+595" },
    { "name": "Peru", "callingCode": "+51" },
    { "name": "Philippines", "callingCode": "+63" },
    { "name": "Pitcairn", "callingCode": "+64" },
    { "name": "Poland", "callingCode": "+48" },
    { "name": "Portugal", "callingCode": "+351" },
    { "name": "Puerto Rico", "callingCode": "+1787" },
    { "name": "Qatar", "callingCode": "+974" },
    { "name": "Republic of the Congo", "callingCode": "+242" },
    { "name": "Reunion", "callingCode": "+262" },
    { "name": "Romania", "callingCode": "+40" },
    { "name": "Russia", "callingCode": "+7" },
    { "name": "Rwanda", "callingCode": "+250" },
    { "name": "Saint Barthelemy", "callingCode": "+590" },
    { "name": "Saint Helena", "callingCode": "+290" },
    { "name": "Saint Kitts and Nevis", "callingCode": "+1869" },
    { "name": "Saint Lucia", "callingCode": "+1758" },
    { "name": "Saint Martin", "callingCode": "+590" },
    { "name": "Saint Pierre and Miquelon", "callingCode": "+508" },
    { "name": "Saint Vincent and the Grenadines", "callingCode": "+1784" },
    { "name": "Samoa", "callingCode": "+685" },
    { "name": "San Marino", "callingCode": "+378" },
    { "name": "Sao Tome and Principe", "callingCode": "+239" },
    { "name": "Saudi Arabia", "callingCode": "+966" },
    { "name": "Senegal", "callingCode": "+221" },
    { "name": "Serbia", "callingCode": "+381" },
    { "name": "Seychelles", "callingCode": "+248" },
    { "name": "Sierra Leone", "callingCode": "+232" },
    { "name": "Singapore", "callingCode": "+65" },
    { "name": "Sint Maarten", "callingCode": "+1721" },
    { "name": "Slovakia", "callingCode": "+421" },
    { "name": "Slovenia", "callingCode": "+386" },
    { "name": "Solomon Islands", "callingCode": "+677" },
    { "name": "Somalia", "callingCode": "+252" },
    { "name": "South Africa", "callingCode": "+27" },
    { "name": "South Korea", "callingCode": "+82" },
    { "name": "South Sudan", "callingCode": "+211" },
    { "name": "Spain", "callingCode": "+34" },
    { "name": "Sri Lanka", "callingCode": "+94" },
    { "name": "Sudan", "callingCode": "+249" },
    { "name": "Suriname", "callingCode": "+597" },
    { "name": "Svalbard and Jan Mayen", "callingCode": "+47" },
    { "name": "Swaziland", "callingCode": "+268" },
    { "name": "Sweden", "callingCode": "+46" },
    { "name": "Switzerland", "callingCode": "+41" },
    { "name": "Syria", "callingCode": "+963" },
    { "name": "Taiwan", "callingCode": "+886" },
    { "name": "Tajikistan", "callingCode": "+992" },
    { "name": "Tanzania", "callingCode": "+255" },
    { "name": "Thailand", "callingCode": "+66" },
    { "name": "Togo", "callingCode": "+228" },
    { "name": "Tokelau", "callingCode": "+690" },
    { "name": "Tonga", "callingCode": "+676" },
    { "name": "Trinidad and Tobago", "callingCode": "+1868" },
    { "name": "Tunisia", "callingCode": "+216" },
    { "name": "Turkey", "callingCode": "+90" },
    { "name": "Turkmenistan", "callingCode": "+993" },
    { "name": "Turks and Caicos Islands", "callingCode": "+1649" },
    { "name": "Tuvalu", "callingCode": "+688" },
    { "name": "U.S. Virgin Islands", "callingCode": "+1340" },
    { "name": "Uganda", "callingCode": "+256" },
    { "name": "Ukraine", "callingCode": "+380" },
    { "name": "United Arab Emirates", "callingCode": "+971" },
    { "name": "United Kingdom", "callingCode": "+44" },
    { "name": "United States", "callingCode": "+1" },
    { "name": "Uruguay", "callingCode": "+598" },
    { "name": "Uzbekistan", "callingCode": "+998" },
    { "name": "Vanuatu", "callingCode": "+678" },
    { "name": "Vatican", "callingCode": "+379" },
    { "name": "Venezuela", "callingCode": "+58" },
    { "name": "Vietnam", "callingCode": "+84" },
    { "name": "Wallis and Futuna", "callingCode": "+681" },
    { "name": "Western Sahara", "callingCode": "+212" },
    { "name": "Yemen", "callingCode": "+967" },
    { "name": "Zambia", "callingCode": "+260" },
    { "name": "Zimbabwe", "callingCode": "+263" },
];
const genders = [
    {
        "gender": "Female"
    },
    {
        "gender": "Male"
    },
    {
        "gender": "Other"
    }
];
const bloodGroups = [
    {
        "bloodGroup": "A+"
    },
    {
        "bloodGroup": "A-"
    },
    {
        "bloodGroup": "B+"
    },
    {
        "bloodGroup": "B-"
    },
    {
        "bloodGroup": "O+"
    },
    {
        "bloodGroup": "O-"
    },
    {
        "bloodGroup": "AB+"
    },
    {
        "bloodGroup": "AB-"
    }
];

const distinct = (arr) => {
    return [...new Set(arr)];
};

const getServices = () => {
    return distinct(locations.map(x => x.service).concat(durations.map(x => x.service)));
};

const getLocations = ({ service }) => {
    if (service) {
        return distinct(locations.filter(x => x.service === service).map(x => x.location));
    } else {
        return [];
    }
};

const getBatches = ({ service, location }) => {
    if (service && location) {
        return distinct(locations.filter(x => x.service === service && x.location === location).map(x => x.batch).filter(x => x !== ""));
    } else {
        return [];
    }
};

const getDurations = ({ service }) => {
    if (service) {
        return distinct(durations.filter(x => x.service === service).map(x => x.duration));
    } else {
        return [];
    }
};

const getPaymentOptions = ({ service, duration }) => {
    if (service && duration) {
        return distinct(durations.filter(x => x.service === service && x.duration === duration).map(x => x.paymentOption));
    } else {
        return [];
    }
};

// const getAmountInINR = ({ service, duration, paymentOption }) => {
//     if (service && duration && paymentOption) {
//         const temp = durations.find(x => x.service === service && x.duration === duration && x.paymentOption === paymentOption);
//         if (temp) {
//             return temp.amountInINR;
//         } else {
//             return "";
//         }
//     } else {
//         return "";
//     }
// };

const getAmountInINR = ({ service, duration, paymentOption , location }) => {
    if (service && duration === "3 Months" && paymentOption && (location === "Chaos" || location === "Teynampet" || location === "Velachery" || location === "Nungambakkam")){
        let temp = durations.find(x => x.service === service && x.duration === duration && x.paymentOption === paymentOption && x.location === location);
        if (temp) {
            return temp.amountInINR;
        } else {
            return "";
        }
    } else if (service && duration && paymentOption) {
        let temp = durations.find(x => x.service === service && x.duration === duration && x.paymentOption === paymentOption);
        if (temp) {
            return temp.amountInINR;
        } else {
            return "";
        }
    }else {
        return "";
    }
};

const getCountries = () => {
    return countryNamesAndCallingCodes.map(x => x.name);
};

const getCountryCodes = () => {
    return countryNamesAndCallingCodes.map(x => x.name + " (" + x.callingCode +")")
};

const getGenders = () => {
    return genders.map(x => x.gender);
};

const getBloodGroups = () => {
    return bloodGroups.map(x => x.bloodGroup);
};

export default {
    getServices,
    getLocations,
    getBatches,
    getDurations,
    getPaymentOptions,
    getAmountInINR,
    getCountries,
    getCountryCodes,
    getGenders,
    getBloodGroups
};
