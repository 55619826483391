import React, { useState, useEffect } from "react";
import "react-progress-2/main.css";
import Progress from "react-progress-2";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TermsAndConditions from "./TermsAndConditions";
import Header from "../components/Header";
import VerticalContainer from "../components/VerticalContainer";
import HorizontalContainer from "../components/HorizontalContainer";
import TextField from "../components/TextField";
import Button from "../components/Button";
import DatePicker from "../components/DatePicker";
import DropDown from "../components/DropDown";
import configAPI from "../config-api";
import PaymentReceipt from "../components/PaymentReceipt";

import pinCodesInIndia from "./pinCodesInIndia";



const has10Digits = (num) => {
    return (num > 999999999) && (num < 10000000000);
};

const isBasicUserInfoValid = ({ toCheck, name, email, countryCode, countryCodes, phoneNumber }) => {
    if (toCheck === "name") {
        const isValid = name !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter your name"
        };
    }

    if (toCheck === "email") {
        if (email === "") {
            const isValid = false;

            return {
                isValid,
                helperText: "Please enter your email"
            };
        } else {
            const isValid = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    
            return {
                isValid,
                helperText: isValid ? "" : "Please enter a valid email"
            };
        }
    }

    if (toCheck === "countryCode") {
        const isValid = countryCodes.includes(countryCode);

        return {
            isValid,
            helperText: isValid ? "" : "Please select a country code"
        };
    }

    if (toCheck === "phoneNumber") {
        const isValid = countryCode === "India (+91)" ?
            has10Digits(phoneNumber) :
            phoneNumber !== "";
        
        return {
            isValid,
            helperText: isValid ? "" : "Please enter a valid phone number"
        };
    }
};

const isMoreUserInfoValid = ({ 
    toCheck,
    homeAddress, 
    pinCode,
    city,
    country, countries,
    dateOfBirth, 
    gender, genders,
    bloodGroup, bloodGroups,
    occupation, 
    emergencyContactName, 
    emergencyContactRelation, 
    emergencyContactCountryCode, emergencyContactCountryCodes,
    emergencyContactPhoneNumber
}) => {
    if (toCheck === "homeAddress") {
        const isValid = homeAddress !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter your home address"
        };
    }

    if (toCheck === "pinCode") {
        let isValid = false;

        if (country === "India") {
            isValid = pinCodesInIndia.includes(parseInt(pinCode));
        } else {
            isValid = pinCode !== "";
        }
        
        return {
            isValid,
            helperText: isValid ? "" : "Please enter a valid pincode"
        };
    }

    if (toCheck === "city") {
        const isValid = city !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter a valid city"
        };
    }

    if (toCheck === "country") {
        const isValid = countries.includes(country);

        return {
            isValid,
            helperText: isValid ? "" : "Please select a country"
        };
    }

    if (toCheck === "dateOfBirth") {
        const isValid = moment(dateOfBirth, "YYYY-M-D", true).isValid();

        return {
            isValid,
            helperText: isValid ? "" : "Please select your date of birth"
        };
    }

    if (toCheck === "gender") {
        const isValid = genders.some(x => x === gender);

        return {
            isValid,
            helperText: isValid ? "" : "Please select a gender"
        };
    }

    if (toCheck === "bloodGroup") {
        const isValid = bloodGroups.some(x => x === bloodGroup);

        return {
            isValid,
            helperText: isValid ? "" : "Please select a blood group"
        };
    }

    if (toCheck === "occupation") {
        const isValid = occupation !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter your occupation"
        };
    }

    if (toCheck === "emergencyContactName") {
        const isValid = emergencyContactName !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter your emergency contact's name"
        };
    }

    if (toCheck === "emergencyContactRelation") {
        const isValid = emergencyContactRelation !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter your emergency contact's relation to you"
        };
    }

    if (toCheck === "emergencyContactCountryCode") {
        const isValid = emergencyContactCountryCodes.includes(emergencyContactCountryCode);

        return {
            isValid,
            helperText: isValid ? "" : "Please select a country code"
        };
    }

    if (toCheck === "emergencyContactPhoneNumber") {
        const isValid = emergencyContactCountryCode === "India (+91)" ?
            has10Digits(emergencyContactPhoneNumber) :
            emergencyContactPhoneNumber !== "";

        return {
            isValid,
            helperText: isValid ? "" : "Please enter a valid phone number"
        };
    }
};

const isSubscriptionAndPaymentInfoValid = ({ 
    toCheck,
    service, services, 
    location, locations, 
    batch, batches, 
    duration, durations,
    paymentOption, paymentOptions,
    amountInINR, isAmountInINRReadOnly,
    comments, areCommentsRequired,
}) => {
    if (toCheck === "service") {
        const isValid = services.some((x) => x === service);

        return {
            isValid,
            "helperText": isValid ? "" : "Please select a service"
        };
    }

    if (toCheck === "location") {
        const isValid = locations.some((x) => x === location);

        return {
            isValid,
            "helperText": isValid ? "" : "Please select a location"
        };
    }

    if (toCheck === "batch") {
        if (batch === "") {
            const isValid = batches.length === 0;

            return {
                isValid,
                "helperText": isValid ? "" : "Please select a batch"
            };
        } else {
            const isValid = batches.some((x) => x === batch);

            return {
                isValid,
                "helperText": isValid ? "" : "Please select a batch"
            };
        }
    }

    if (toCheck === "duration") {
        const isValid = durations.some((x) => x === duration);

        return {
            isValid,
            "helperText": isValid ? "" : "Please select a duration"
        };
    }

    if (toCheck === "paymentOption") {
        const isValid = paymentOptions.some((x) => x === paymentOption);

        return {
            isValid,
            "helperText": isValid ? "" : "Please select a payment option"
        };
    }

    if (toCheck === "amountInINR") {
        if (isAmountInINRReadOnly) {
            const isValid = amountInINR === configAPI.getAmountInINR({ service, duration, paymentOption });

            return {
                isValid,
                "helperText": isValid ? "" : "Something wrong. Please reselect duration and payment option"
            };
        } else {
            const isValid = amountInINR > 0;

            return {
                isValid,
                "helperText": isValid ? "" : "Please enter an amount greater than 0"
            };
        }
    }

    if (toCheck === "comments") {
        if (paymentOption === "Pay a custom amount") {
            const isValid = comments !== "";

            return {
                isValid,
                "helperText": isValid ? "" : "Please enter the reason why you are paying a custom amount"
            };
        } else {
            const isValid = true;

            return {
                isValid,
                "helperText": isValid ? "" : "Please enter comments"
            };
        }
    }
};

const createOrder = async (info) => {
    const response = await window.fetch("/create-order", {
        "method": "post",
        "headers": {
            'Content-Type': 'application/json',
            'Accept': "application/json",
        },
        "body": JSON.stringify(info)
    });

    if (response.status && response.status !== 200) {
        throw response;
    } else {
        return await response.json();
    }
};

const markOrderAsPaid = async ({ quadOrderId, razorpayPaymentId }) => {
    const response = await window.fetch("/mark-order-as-paid", {
        "method": "post",
        "headers": {
            'Content-Type': 'application/json',
            'Accept': "application/json",
        },
        "body": JSON.stringify({ quadOrderId, razorpayPaymentId })
    });

    if (response.status && response.status !== 200) {
        throw response;
    }

    return response;
};



const TheOneScreen = () => {
    const [screenToShow, setScreenToShow] = useState("BasicUserInfo");
    
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");
    
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");

    const [countryCodes, setCountryCodes] = useState([]);
    const [countryCode, setCountryCode] = useState("");
    const [countryCodeError, setCountryCodeError] = useState(false);
    const [countryCodeHelperText, setCountryCodeHelperText] = useState("");
    
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

    const [homeAddress, setHomeAddress] = useState("");
    const [homeAddressError, setHomeAddressError] = useState(false);
    const [homeAddressHelperText, setHomeAddressHelperText] = useState("");

    const [pinCode, setPinCode] = useState("");
    const [pinCodeError, setPinCodeError] = useState(false);
    const [pinCodeHelperText, setPinCodeHelperText] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    const [cityHelperText, setCityHelperText] = useState("");

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [countryError, setCountryError] = useState(false);
    const [countryHelperText, setCountryHelperText] = useState("");

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dateOfBirthError, setDateOfBirthError] = useState(false);
    const [dateOfBirthHelperText, setDateOfBirthHelperText] = useState("");

    const [genders, setGenders] = useState([]);
    const [gender, setGender] = useState("");
    const [genderError, setGenderError] = useState(false);
    const [genderHelperText, setGenderHelperText] = useState("");

    const [bloodGroups, setBloodGroups] = useState([]);
    const [bloodGroup, setBloodGroup] = useState("");
    const [bloodGroupError, setBloodGroupError] = useState(false);
    const [bloodGroupHelperText, setBloodGroupHelperText] = useState("");

    const [occupation, setOccupation] = useState("");
    const [occupationError, setOccupationError] = useState(false);
    const [occupationHelperText, setOccupationHelperText] = useState("");

    const [emergencyContactName, setEmergencyContactName] = useState("");
    const [emergencyContactNameError, setEmergencyContactNameError] = useState(false);
    const [emergencyContactNameHelperText, setEmergencyContactNameHelperText] = useState("");

    const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
    const [emergencyContactRelationError, setEmergencyContactRelationError] = useState(false);
    const [emergencyContactRelationHelperText, setEmergencyContactRelationHelperText] = useState("");

    const [emergencyContactCountryCodes, setEmergencyContactCountryCodes] = useState([]);
    const [emergencyContactCountryCode, setEmergencyContactCountryCode] = useState("");
    const [emergencyContactCountryCodeError, setEmergencyContactCountryCodeError] = useState(false);
    const [emergencyContactCountryCodeHelperText, setEmergencyContactCountryCodeHelperText] = useState("");

    const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = useState("");
    const [emergencyContactPhoneNumberError, setEmergencyContactPhoneNumberError] = useState(false);
    const [emergencyContactPhoneNumberHelperText, setEmergencyContactPhoneNumberHelperText] = useState("");

    const [services, setServices] = useState([]);
    const [service, setService] = useState("");
    const [serviceError, setServiceError] = useState(false);
    const [serviceHelperText, setServiceHelperText] = useState("");

    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState("");
    const [locationError, setLocationError] = useState(false);
    const [locationHelperText, setLocationHelperText] = useState("");

    const [batches, setBatches] = useState([]);
    const [batch, setBatch] = useState("");
    const [batchError, setBatchError] = useState(false);
    const [batchHelperText, setBatchHelperText] = useState("");

    const [durations, setDurations] = useState([]);
    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState(false);
    const [durationHelperText, setDurationHelperText] = useState("");

    const [paymentOptions, setPaymentOptions] = useState([]);
    const [paymentOption, setPaymentOption] = useState("");
    const [paymentOptionError, setPaymentOptionError] = useState(false);
    const [paymentOptionHelperText, setPaymentOptionHelperText] = useState("");

    const [amountInINR, setAmountInINR] = useState("");
    const [amountInINRError, setAmountInINRError] = useState(false);
    const [amountInINRHelperText, setAmountInINRHelperText] = useState("");

    const [isAmountInINRReadOnly, setIsAmountInINRReadOnly] = useState(true);

    const [customAmountCommentsOptions, setCustomAmountCommentsOptions] = useState([]); 
    const [comments, setComments] = useState("");
    const [commentsError, setCommentsError] = useState(false);
    const [commentsHelperText, setCommentsHelperText] = useState("");

    const [areCommentsRequired, setAreCommentsRequired] = useState(false);

    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);

    const [orderData, setOrderData] = useState({});

    useEffect(() => setCountryCodes(configAPI.getCountryCodes()), [ ]);
    useEffect(() => setCountries(configAPI.getCountries()), [ ]);
    useEffect(() => setEmergencyContactCountryCodes(configAPI.getCountryCodes()), [ ]);
    useEffect(() => setGenders(configAPI.getGenders()), [ ]);
    useEffect(() => setBloodGroups(configAPI.getBloodGroups()), [ ]);
    useEffect(() => setServices(configAPI.getServices()), [ ]);
    useEffect(() => setService((services && services.length === 1) ? services[0] : ""), [ services ]);
    useEffect(() => setLocations(configAPI.getLocations({ service })), [ service ]);
    useEffect(() => setLocation((locations && locations.length === 1) ? locations[0] : ""), [ locations ]);
    useEffect(() => setBatches(configAPI.getBatches({ service, location })), [ service, location ]);
    useEffect(() => setBatch((batches && batches.length === 1) ? batches[0] : ""), [ batches ]);
    useEffect(() => setDurations(configAPI.getDurations({ service })), [ service ]);
    useEffect(() => setDuration((durations && durations.length === 1) ? durations[0] : ""), [ durations ]);
    useEffect(() => setPaymentOptions(configAPI.getPaymentOptions({ service, duration })), [ service, duration ]);
    useEffect(() => setPaymentOption((paymentOptions && paymentOptions.length === 1) ? paymentOptions[0] : ""), [ paymentOptions]);
    // useEffect(() => setAmountInINR(configAPI.getAmountInINR({ service, duration, paymentOption })), [ service, duration, paymentOption ]);
    useEffect(() => setAmountInINR(configAPI.getAmountInINR({ service, duration, paymentOption, location })), [ service, duration, paymentOption, location ]);
    useEffect(() => setIsAmountInINRReadOnly(!(paymentOption === "Pay a custom amount")), [ paymentOption ]);
    useEffect(() => setCustomAmountCommentsOptions([ "Discounts", "Paying for more than one person", "Operations mailed me this specific amount" ]), [ ]);
    useEffect(() => setAreCommentsRequired(paymentOption === "Pay a custom amount"), [ paymentOption ]);

    const onClickOfBasicUserInfoNextButton = async () => {
        const isNameValid = isBasicUserInfoValid({ toCheck: "name", name });
        const isEmailValid = isBasicUserInfoValid({ toCheck: "email", email });
        const isCountryCodeValid = isBasicUserInfoValid({ toCheck: "countryCode", countryCode, countryCodes });
        const isPhoneNumberValid = isBasicUserInfoValid({ toCheck: "phoneNumber", phoneNumber, countryCode });

        setNameError(!isNameValid.isValid);
        setNameHelperText(isNameValid.helperText);

        setEmailError(!isEmailValid.isValid);
        setEmailHelperText(isEmailValid.helperText);

        setCountryCodeError(!isCountryCodeValid.isValid);
        setCountryCodeHelperText(isCountryCodeValid.helperText);

        setPhoneNumberError(!isPhoneNumberValid.isValid);
        setPhoneNumberHelperText(isPhoneNumberValid.helperText);

        if (isNameValid.isValid && isEmailValid.isValid && isCountryCodeValid.isValid && isPhoneNumberValid.isValid) {
            Progress.show();
            const response = await window.fetch("/get-screen-to-show-after-basic-user-info", {
                "method": "post",
                "headers": {
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                },
                "body": JSON.stringify({ name, email, countryCode, phoneNumber })
            });
        
            if (response.status && response.status !== 200) {
                setScreenToShow("MoreUserInfo");
            } else {
                const responseJson = await response.json();
                setScreenToShow(responseJson.screenToShow);
            }
            Progress.hide();
        }
    };

    const onClickOfMoreUserInfoNextButton = () => {
        const isHomeAddressValid = isMoreUserInfoValid({ "toCheck": "homeAddress", homeAddress });
        const isPinCodeValid = isMoreUserInfoValid({ "toCheck": "pinCode", pinCode, country });
        const isCityValid = isMoreUserInfoValid({ "toCheck": "city", city });
        const isCountryValid = isMoreUserInfoValid({ "toCheck": "country", country, countries });
        const isDateOfBirthValid = isMoreUserInfoValid({ "toCheck": "dateOfBirth", dateOfBirth });
        const isGenderValid = isMoreUserInfoValid({ "toCheck": "gender", gender, genders });
        const isBloodGroupValid = isMoreUserInfoValid({ "toCheck": "bloodGroup", bloodGroup, bloodGroups });
        const isOccupationValid = isMoreUserInfoValid({ "toCheck": "occupation", occupation });
        const isEmergencyContactNameValid = isMoreUserInfoValid({ "toCheck": "emergencyContactName", emergencyContactName });
        const isEmergencyContactRelationValid = isMoreUserInfoValid({ "toCheck": "emergencyContactRelation", emergencyContactRelation });
        const isEmergencyContactCountryCodeValid = isMoreUserInfoValid({ "toCheck": "emergencyContactCountryCode", emergencyContactCountryCode, emergencyContactCountryCodes });
        const isEmergencyContactPhoneNumberValid = isMoreUserInfoValid({ "toCheck": "emergencyContactPhoneNumber", emergencyContactPhoneNumber, emergencyContactCountryCode });

        setHomeAddressError(!isHomeAddressValid.isValid);
        setHomeAddressHelperText(isHomeAddressValid.helperText);

        setPinCodeError(!isPinCodeValid.isValid);
        setPinCodeHelperText(isPinCodeValid.helperText);

        setCityError(!isCityValid.isValid);
        setCityHelperText(isCityValid.helperText);

        setCountryError(!isCountryValid.isValid);
        setCountryHelperText(isCountryValid.helperText);

        setDateOfBirthError(!isDateOfBirthValid.isValid);
        setDateOfBirthHelperText(isDateOfBirthValid.helperText);

        setGenderError(!isGenderValid.isValid);
        setGenderHelperText(isGenderValid.helperText);

        setBloodGroupError(!isBloodGroupValid.isValid);
        setBloodGroupHelperText(isBloodGroupValid.helperText);

        setOccupationError(!isOccupationValid.isValid);
        setOccupationHelperText(isOccupationValid.helperText);

        setEmergencyContactNameError(!isEmergencyContactNameValid.isValid);
        setEmergencyContactNameHelperText(isEmergencyContactNameValid.helperText);

        setEmergencyContactRelationError(!isEmergencyContactRelationValid.isValid);
        setEmergencyContactRelationHelperText(isEmergencyContactRelationValid.helperText);

        setEmergencyContactCountryCodeError(!isEmergencyContactCountryCodeValid.isValid);
        setEmergencyContactCountryCodeHelperText(isEmergencyContactCountryCodeValid.helperText);

        setEmergencyContactPhoneNumberError(!isEmergencyContactPhoneNumberValid.isValid);
        setEmergencyContactPhoneNumberHelperText(isEmergencyContactPhoneNumberValid.helperText);

        if (
            isHomeAddressValid.isValid && 
            isPinCodeValid.isValid &&
            isCityValid.isValid &&
            isCountryValid.isValid &&
            isDateOfBirthValid.isValid && 
            isGenderValid.isValid && 
            isBloodGroupValid.isValid && 
            isOccupationValid.isValid && 
            isEmergencyContactNameValid.isValid && 
            isEmergencyContactRelationValid.isValid && 
            isEmergencyContactCountryCodeValid.isValid &&
            isEmergencyContactPhoneNumberValid.isValid
        ) {
            setScreenToShow("SubscriptionAndPaymentInfo");
        }
    };

    const onClickOfMoreUserInfoBackButton = () => {
        setScreenToShow("BasicUserInfo");
    };

    const onClickOfSubscriptionAndPaymentInfoBackButton = () => {
        setScreenToShow("MoreUserInfo");
    };

    const onClickOfSubscriptionAndPaymentInfoNextButton = () => {
        const isServiceValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "service", service, services });
        const isLocationValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "location", location, locations });
        const isBatchValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "batch", batch, batches });
        const isDurationValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "duration", duration, durations });
        const isPaymentOptionValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "paymentOption", paymentOption, paymentOptions });
        const isAmountInINRValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "amountInINR", amountInINR, service, duration, paymentOption });
        const areCommentsValid = isSubscriptionAndPaymentInfoValid({ "toCheck": "comments", comments, paymentOption });

        setServiceError(!isServiceValid.isValid);
        setServiceHelperText(isServiceValid.helperText);

        setLocationError(!isLocationValid.isValid);
        setLocationHelperText(isLocationValid.helperText);

        setBatchError(!isBatchValid.isValid);
        setBatchHelperText(isBatchValid.helperText);

        setDurationError(!isDurationValid.isValid);
        setDurationHelperText(isDurationValid.helperText);
        
        setPaymentOptionError(!isPaymentOptionValid.isValid);
        setPaymentOptionHelperText(isPaymentOptionValid.helperText);

        setAmountInINRError(!isAmountInINRValid.isValid);
        setAmountInINRHelperText(isAmountInINRValid.helperText);

        setCommentsError(!areCommentsValid.isValid);
        setCommentsHelperText(areCommentsValid.helperText);

        if (
            isServiceValid.isValid &&
            isLocationValid.isValid &&
            isBatchValid.isValid &&
            isDurationValid.isValid &&
            isPaymentOptionValid.isValid &&
            isAmountInINRValid.isValid &&
            areCommentsValid.isValid
        ) {
            setScreenToShow("ConfirmAndPay");
        }
    };

    const onClickOfConfirmAndPayBackButton = () => {
        setScreenToShow("SubscriptionAndPaymentInfo");
    };

    const onClickOfConfirmAndPayPayButton = async () => {
        if (!agreeToTermsAndConditions) {
            window.alert("Please agree to terms and conditions");
            return;
        }

        Progress.show();
        try {
            const info = {
                name,
                email,
                countryCode,
                phoneNumber,
                homeAddress,
                pinCode,
                city,
                country,
                dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
                gender,
                bloodGroup,
                occupation,
                emergencyContactName,
                emergencyContactRelation,
                emergencyContactCountryCode,
                emergencyContactPhoneNumber,
                service,
                location,
                batch,
                duration,
                paymentOption,
                amountInINR,
                comments
            };
    
            const orderIds = await createOrder(info);

            const onPaymentSuccess = async (razorpayResponse) => {
                Progress.show();
                const orderDataResponse = await markOrderAsPaid({
                    "quadOrderId": orderIds.quadOrderId, 
                    "razorpayPaymentId": razorpayResponse.razorpay_payment_id
                });
                const orderData = await orderDataResponse.json();
                setOrderData(orderData);
                setScreenToShow("ThankYouBye");
                Progress.hide();
            };
            
            const options = {
                "key": "rzp_live_mIi82TEEfN8i9x",
                "amount": info.amountInINR * 100,
                "currency": "INR",
                "image": process.env.PUBLIC_URL + "/quad-logo.png",
                "order_id": orderIds.razorpayOrderId,
                "handler": onPaymentSuccess,
                "prefill": {
                    "name": info.name,
                    "email": info.email,
                    "contact": info.phoneNumber
                },
                "theme": {
                    "color": "#F37254"
                }
            };
            const Razorpay = window.Razorpay;
            const RazorpayInstance = new Razorpay(options);
            RazorpayInstance.open();
        } catch (e) {
            window.alert("Error : " + e.status + " " + e.url);
        }
        Progress.hide();
    };

    const onClickOfTermsAndConditionsBackButton = () => {
        setScreenToShow("ConfirmAndPay");
    };

    return (
        < VerticalContainer style={{alignItems:"center"}}>
            <Header />
            <Progress.Component/>
            {
                screenToShow === "BasicUserInfo" ?
                <VerticalContainer style={{alignItems:"center", width:"50vw"}}>
                    <TextField required label="Name" 
                        value={name} helperText={nameHelperText} error={nameError}
                        onChange={(event) => setName(event.target.value)} />
                    <TextField required label="Email" type="email" 
                        value={email} helperText={emailHelperText} error={emailError}
                        onChange={(event) => setEmail(event.target.value)} />
                    <DropDown required label="Country Code"
                        values={countryCodes} selectedValue={countryCode} setSelectedValue={setCountryCode}
                        helperText={countryCodeHelperText} error={countryCodeError} />
                    <TextField required label="Phone Number" type="number"
                        value={phoneNumber} helperText={phoneNumberHelperText} error={phoneNumberError}
                        onChange={(event) => setPhoneNumber(event.target.value)} />
                    <Button style={{width:"20vw"}} label="Next" onClick={onClickOfBasicUserInfoNextButton} />
                </VerticalContainer> :
                null
            }
            {
                screenToShow === "MoreUserInfo" ?
                <VerticalContainer style={{alignItems:"center", width:"50vw"}}>
                    <TextField required label="Home Address" type="homeAddress" multiline
                        value={homeAddress} helperText={homeAddressHelperText} error={homeAddressError}
                        onChange={(event) => setHomeAddress(event.target.value)} />
                    <TextField required label="Pin Code" type="number"
                        value={pinCode} helperText={pinCodeHelperText} error={pinCodeError}
                        onChange={(event) => setPinCode(event.target.value)} />
                    <TextField required label="City" type="city"
                        value={city} helperText={cityHelperText} error={cityError}
                        onChange={(event) => setCity(event.target.value)} />
                    <DropDown required label="Country"
                        values={countries} selectedValue={country} setSelectedValue={setCountry}
                        helperText={countryHelperText} error={countryError} />
                    <DatePicker required label="Date Of Birth" type="dateOfBirth" 
                        value={dateOfBirth} helperText={dateOfBirthHelperText} error={dateOfBirthError}
                        onChange={(date) => setDateOfBirth(date)} />
                    <DropDown required label="Gender"
                        values={genders} selectedValue={gender} setSelectedValue={setGender}
                        helperText={genderHelperText} error={genderError} />
                    <DropDown required label="Blood Group"
                        values={bloodGroups} selectedValue={bloodGroup} setSelectedValue={setBloodGroup}
                        helperText={bloodGroupHelperText} error={bloodGroupError} />
                    <TextField required label="Occupation" type="occupation" 
                        value={occupation} helperText={occupationHelperText} error={occupationError}
                        onChange={(event) => setOccupation(event.target.value)} />
                    <TextField required label="Emergency Contact Name" type="emergencyContactName" 
                        value={emergencyContactName} helperText={emergencyContactNameHelperText} error={emergencyContactNameError}
                        onChange={(event) => setEmergencyContactName(event.target.value)} />
                    <TextField required label="Emergency Contact Relation" type="emergencyContactRelation" 
                        value={emergencyContactRelation} helperText={emergencyContactRelationHelperText} error={emergencyContactRelationError}
                        onChange={(event) => setEmergencyContactRelation(event.target.value)} />
                    <DropDown required label="Emergency Contact Country Code"
                        values={emergencyContactCountryCodes} selectedValue={emergencyContactCountryCode} setSelectedValue={setEmergencyContactCountryCode}
                        helperText={emergencyContactCountryCodeHelperText} error={emergencyContactCountryCodeError} />
                    <TextField required label="Emergency Contact Phone Number" type="number" 
                        value={emergencyContactPhoneNumber} helperText={emergencyContactPhoneNumberHelperText} error={emergencyContactPhoneNumberError}
                        onChange={(event) => setEmergencyContactPhoneNumber(event.target.value)} />
                    <HorizontalContainer style={{justifyContent:"space-between", marginTop:"2vh"}}>
                        <Button style={{width:"20vw"}} label="Back" onClick={onClickOfMoreUserInfoBackButton} />
                        <Button style={{width:"20vw"}} label="Next" onClick={onClickOfMoreUserInfoNextButton} />
                    </HorizontalContainer>
                </VerticalContainer> :
                null
            }
            {
                screenToShow === "SubscriptionAndPaymentInfo" ?
                <VerticalContainer style={{alignItems:"center", width:"50vw"}}>
                    <DropDown required label="Service"
                        values={services} selectedValue={service} setSelectedValue={setService}
                        helperText={serviceHelperText} error={serviceError} />
                    <DropDown required label="Location"
                        values={locations} selectedValue={location} setSelectedValue={setLocation}
                        helperText={locationHelperText} error={locationError} />
                    <DropDown required label="Batch"
                        values={batches} selectedValue={batch} setSelectedValue={setBatch}
                        helperText={batchHelperText} error={batchError} />
                    <DropDown required label="Duration"
                        values={durations} selectedValue={duration} setSelectedValue={setDuration}
                        helperText={durationHelperText} error={durationError} />
                    <DropDown required label="Payment Option"
                        values={paymentOptions} selectedValue={paymentOption} setSelectedValue={setPaymentOption}
                        helperText={paymentOptionHelperText} error={paymentOptionError} />
                    <TextField readOnly={Boolean(isAmountInINRReadOnly)} required={!Boolean(isAmountInINRReadOnly)} label="Amount In INR" type="number"
                        value={amountInINR} helperText={amountInINRHelperText} error={amountInINRError}
                        onChange={(event) => setAmountInINR(event.target.value)} />
                    {
                        paymentOption === "Pay a custom amount" ?
                        <DropDown required={Boolean(areCommentsRequired)} label="Comments"
                            values={customAmountCommentsOptions} selectedValue={comments} setSelectedValue={setComments}
                            helperText={commentsHelperText} error={commentsError} /> :
                        <TextField required={Boolean(areCommentsRequired)} label="Comments"
                            value={comments} helperText={commentsHelperText} error={commentsError}
                            onChange={(event) => setComments(event.target.value)} />
                    }
                    <HorizontalContainer style={{justifyContent:"space-between", marginTop:"2vh"}}>
                        <Button style={{width:"20vw"}} label="Back" onClick={onClickOfSubscriptionAndPaymentInfoBackButton} />
                        <Button style={{width:"20vw"}} label="Next" onClick={onClickOfSubscriptionAndPaymentInfoNextButton} />
                    </HorizontalContainer>
                </VerticalContainer> :
                null
            }
            {
                screenToShow === "ConfirmAndPay" ?
                <VerticalContainer style={{alignItems:"center", width:"50vw"}}>
                    <TextField readOnly disabled label="Name" value={name} />
                    <TextField readOnly disabled label="Email" value={email} />
                    <TextField readOnly disabled label="Phone Number" value={phoneNumber} />
                    <TextField readOnly disabled label="Service" value={service} />
                    <TextField readOnly disabled label="Location" value={location} />
                    {
                        batch !== "" ?
                        <TextField readOnly disabled label="Batch" value={batch} /> :
                        null
                    }
                    <TextField readOnly disabled label="Duration" value={duration} />
                    <TextField readOnly disabled label="Payment Option" value={paymentOption} />
                    {
                        paymentOption === "Pay a custom amount" ?
                        <TextField readOnly disabled label="Comments" value={comments} /> :
                        null
                    }
                    <TextField readOnly disabled label="Amount In INR" value={amountInINR} />
                    <HorizontalContainer>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeToTermsAndConditions}
                                    onChange={() => setAgreeToTermsAndConditions((prevState) => !prevState)}
                                    color="primary"
                                />
                            }
                        />
                        <p>
                            {"I agree with Terms and Conditions. Please "}
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                setScreenToShow("TermsAndConditions");
                            }} >{"click here"}</a>
                            {" to read them"}
                        </p>
                    </HorizontalContainer>
                    <HorizontalContainer style={{justifyContent:"space-between", marginTop:"2vh"}}>
                        <Button style={{width:"20vw"}} label="Back" onClick={onClickOfConfirmAndPayBackButton} />
                        <Button style={{width:"20vw"}} label="Pay" onClick={onClickOfConfirmAndPayPayButton} />
                    </HorizontalContainer>
                </VerticalContainer> :
                null
            }
            {
                screenToShow === "TermsAndConditions" ?
                <TermsAndConditions onBack={onClickOfTermsAndConditionsBackButton} /> :
                null
            }
            {
                screenToShow === "ThankYouBye" ?
                <VerticalContainer style={{padding: "1rem"}}>
                    <PaymentReceipt orderData={orderData} />
                </VerticalContainer> :
                null
            }
        </VerticalContainer>
    );
};

export default TheOneScreen;