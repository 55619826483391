import React from "react";

const Header = () => {
    const style = {
        backgroundImage: "url(https://thequad.in/wp-content/themes/quad/images/quad-logo.svg)", 
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 0, 0, 0.09)", 
        backgroundOrigin: "content-box",
        padding: "5px", 
        textAlign:"center",
        minHeight: "70px",
        width:"100vw"
    };
    
    return (
        <div style={style} >
            &nbsp;
        </div>
    );
};

export default Header;
