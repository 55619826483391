import React from "react";
import Button from "../components/Button";

const TermsAndConditions = ({ onBack }) => {
    return (
        <div>
            <Button style={{ margin: "0", marginTop: "1vh" }} label="Go Back To Payment" fullWidth onClick={onBack} />
            <div>
                <h1>Terms and conditions</h1>
            </div>
            <article>
                <div>
                    <div>
                        <h2>BootCamp</h2>
                        <ol>
                            <li>The fee needs to be paid  upfront during registration. Cheque, credit card, online payment or cash via  netbanking is accepted.</li>
                            <li>The membership entitles  members to 3 training sessions per week, nutritional support and guidance from  a certified fitness coach depending on individual goals.</li>
                            <li>Membership is allotted to  every member on a first-come, first-served basis since we have limited spots  available in each batch.</li>
                            <li>No alcohol, drugs, or  smoking. You will not be permitted to engage in any activity at The Quad while  under the influence of illegal drugs or alcohol. The Quad does not permit  smoking, alcohol, or illegal drugs, including steroids, in any of its  facilities.</li>
                            <li>No photographic or video  equipment: No photography, videotaping, filming or audio recording will be  allowed without prior written permission of the management.</li>
                            <li>Wearing appropriate  clothing and footwear to classes is a must. Gym shorts, T-shirts, jogging and  sweat outfits are acceptable while formal wear, tight/uncomfortable clothes and  jeans are not.</li>
                            <li>Members are solely  responsible for their own belongings whilst visiting The Quad. Quad Fitness Pvt  Ltd accepts no responsibility for any loss/damage to personal property or for  any item held in lost property. Such items will be kept until claimed, but for  no longer than one week.</li>
                            <li>Members are requested to  carry their own bottle of water to classes.</li>
                            <li>Any member arriving more  than 5 minutes late to a class may not be allowed to take the class. All  members are requested to be present 5 mins before class time.</li>
                            <li>Quad Fitness Pvt Ltd holds  the right to refuse service to anyone. If service is refused to someone post  registration, a refund will be provided on a pro-rated basis.</li>
                            <li>When you sign up for any of The Quad’s workshops, events, services, or programs, you agree to enter areas or spaces (online or offline) where photography, audio, and video recording may occur.<br/>
                            By signing up, you consent to interview(s), photography, audio recording, video recording and its/their release, publication, exhibition, or reproduction to be used for internal training and quality purposes, webcasts, promotional purposes, telecasts, advertising, inclusion on websites, or any other purpose by The Quad and its employees and representatives. You release the Quad, it’s employees, and each and all persons involved from any liability connected with the taking, recording, digitizing, or publication of interviews, photographs, computer images, video, and/or sound recordings.<br/>
                            You have been fully informed of your consent, waiver of liability, and release before signing up for any of The Quad’s services. If you would not like to be part of these recordings, photography, and/or screenshots, please write to hr@thequad.in or inform your coaches in writing of the same, and we will ensure that you are not part of the same.</li>
                            <li>Quad Fitness Pvt Ltd holds  the right to refuse service to anyone who has a preexisting disease condition  or injury at any point if it proves to hinder their training or has the  potential to aggravate. If such a condition is identified, a refund will be  provided after a cancellation fee of Rs 5,000 is subtracted from the total  payment.</li>
                            <li>
                                The Quad strongly  recommends that you clear your participation in this training programme with  your physician. The protocols in the training programme involve high intensity  workouts. While the coaches and trainers at The Quad will scale them and tweak  them to suit your individual needs, it is recommended you understand the  following:
                                <ol>
                                    <li>The Applicant agrees to  participate in physical training sessions instructed by coaches and trainers  affiliated with Quad Fitness Pvt Ltd, and attached herewith a Declaration (and  an Additional Declaration, if applicable).</li>
                                    <li>
                                        The Applicant (and in the  case of a minor Applicant, the guardian of the Applicant) hereby waives all  claims and rights against Quad Fitness Private Limited, and also indemnifies  and agrees to keep indemnified Quad Fitness Private Limited, against all  losses, expenses, costs and damages that Quad Fitness Private Limited may  suffer or incur, as a result of:-
                                        <ol>
                                            <li>Any falsity, incorrectness,  or incompleteness in the Applicant's Declaration or Additional Declaration;</li>
                                            <li>Any injury or illness that  the Applicant may injure or suffer, during the course of the fitness program,  or as a result thereof;</li>
                                            <li>Any injury or illness that  the Applicant may cause to any other participant or observer of the fitness  program, or as a result thereof;</li>
                                            <li>Any actions taken by Quad  Fitness Private Limited as a consequence of any injury or illness which the  Applicant causes, or suffers, or is a contributory to.</li>
                                        </ol>
                                    </li>
                                    <li>The Applicant has fully  read and fully understood the foregoing assumption of risk, and release of  liability. The Applicant acknowledges that this obligates the Applicant to  indemnify the parties named for any liability for injury or death of any person  and damage to property caused by his/her negligent or intentional act or  omission.</li>
                                    <li>The Applicant also confirms  that he/she is consciously waiving claims against Quad Fitness Pvt. Ltd., and  that he/she is doing so after being made fully aware of all risks.</li>
                                    <li>This agreement shall be  binding upon the Applicant, and the Applicant's successors, representatives,  heirs, executers, assigns, or transferees.</li>
                                </ol>
                            </li>
                            <li>If this form is being  signed by a legal guardian, he/she hereby also gives permission to administer  the necessary first aid, and in case of serious illness or injury, to call for  medical and/or surgical care for the child and to transport the child to a  medical facility deemed necessary for the well being of the child. All expenses  incurred in this regard, are to the guardian's account.</li>
                            <li><strong>COMPENSATORY CLASSES</strong>: This  membership is provided for a certain period of time. While your coach will do  everything under his/her power to compensate for missed classes, he/she will  not blindly recommend additional classes since this might result in being  counterproductive to your goals. Your coach will only recommend what is best  for you and it is your responsibility to inform him/her about missed classes,  make an effort to be flexible and schedule compensatory classes.</li>
                            <li>
                                <strong>CANCELLATION</strong>: In an effort  to maintain the high quality service we provide, we register only a limited  number of trainees in each batch. So taking up a spot and then canceling is not  appreciated and comes with a penalty.
                                <ol>
                                    <li>If membership is cancelled  10 days or more before the commencement of the programme, a refund will be  issued after deducting a cancellation fee of Rs 3,000.</li>
                                    <li>If membership is cancelled  1-9 days before the commencement of the programme, a refund will be issued  after deducting a cancellation fee of Rs 6,000. </li>
                                    <li>If membership is cancelled  after the commencement of the programme, a refund will be issued after  deducting a cancellation fee of Rs 9,000. </li>
                                </ol>
                            </li>
                            <li>
                                <strong>DEFERMENT</strong>: For reasons  similar to cancellation, deferring/postponing your membership to a later date  comes with a penalty too.
                                <ol>
                                    <li>If membership is deferred 5  days or more before the commencement of the programme, a credit will be issued  under your name. This will register you for the subsequent quarter.</li>
                                    <li>If membership is deferred  1-4 days before the commencement of the programme, a refund will be issued  after deducting a deferment fee of Rs. 3,000. </li>
                                    <li>If membership is deferred  after the commencement of the programme, a credit after deductions will be  issued under your name. The deductions include a deferment fee of Rs 6,000 and  the pro-rated cost of number of classes conducted until the day of intimation. </li>
                                </ol>
                            </li>
                        </ol>
                        <h2>QuadVirtual</h2>
                        <ol>
                            <li>The fee needs to be paid  upfront during registration. Cheque, credit card, online payment or cash via  netbanking is accepted.</li>
                            <li>The membership entitles  members to a 10-week customised programme with a certified fitness/nutrition  coach who will provide you with a tailor-made plan to suit your own fitness  goals based on your health and medical history. </li>
                            <li>Membership is allotted to  every member on a first-come, first-served basis since we have limited spots  available in with each coach.</li>
                            <li>Due to the online/virtual nature  of this training programme, we will be using photographs and video aids as a  part of the coaching methods. We will be providing you with photographic and  video content for you to consume and will require you to share photos/videos of  your meal plates and workouts as well. </li>
                            <li>Quad Fitness Pvt Ltd holds  the right to refuse service to anyone. If service is refused to someone post  registration, a refund will be provided on a pro-rated basis.</li>
                            <li>Quad Fitness Pvt Ltd holds  the right to refuse service to anyone who has a preexisting disease condition  or injury at any point if it proves to hinder their training or has the  potential to aggravate. If such a condition is identified, a refund will be  provided after a cancellation fee of Rs 5,000 is subtracted from the total  payment.</li>
                            <li>
                                The Quad strongly  recommends that you clear your participation in this training and/or nutrition  programme with your physician. While the QuadVirtual coaches will scale your  workouts, tweak them to suit your individual needs and provide nutritional  recommendations based on information provided by you, it is recommended you  understand the following:
                                <ol>
                                    <li>The Applicant agrees to  participate in physical training sessions instructed by coaches and trainers  affiliated with Quad Fitness Pvt Ltd, and attached herewith a Declaration (and  an Additional Declaration, if applicable).</li>
                                    <li>
                                        The Applicant (and in the  case of a minor Applicant, the guardian of the Applicant) hereby waives all  claims and rights against Quad Fitness Private Limited, and also indemnifies and  agrees to keep indemnified Quad Fitness Private Limited, against all losses,  expenses, costs and damages that Quad Fitness Private Limited may suffer or  incur, as a result of:-
                                        <ol>
                                            <li>Any falsity, incorrectness,  or incompleteness in the Applicant's Declaration or Additional Declaration;</li>
                                            <li>Any injury or illness that  the Applicant may injure or suffer, during the course of the fitness program,  or as a result thereof;</li>
                                            <li>Any injury or illness that  the Applicant may cause to any other participant or observer of the fitness  program, or as a result thereof;</li>
                                            <li>Any actions taken by Quad  Fitness Private Limited as a consequence of any injury or illness which the  Applicant causes, or suffers, or is a contributory to.</li>
                                        </ol>
                                    </li>
                                    <li>The Applicant has fully  read and fully understood the foregoing assumption of risk, and release of  liability. The Applicant acknowledges that this obligates the Applicant to  indemnify the parties named for any liability for injury or death of any person  and damage to property caused by his/her negligent or intentional act or  omission.</li>
                                    <li>The Applicant also confirms  that he/she is consciously waiving claims against Quad Fitness Pvt. Ltd., and  that he/she is doing so after being made fully aware of all risks.</li>
                                    <li>This agreement shall be  binding upon the Applicant, and the Applicant's successors, representatives,  heirs, executers, assigns, or transferees.</li>
                                </ol>
                            </li>
                            <li>If this form is being  signed by a legal guardian, he/she hereby also gives permission to administer  the necessary first aid, and in case of serious illness or injury, to call for  medical and/or surgical care for the child and to transport the child to a  medical facility deemed necessary for the well being of the child. All expenses  incurred in this regard, are to the guardian's account.</li>
                            <li>
                                <strong>CANCELLATION:</strong> In an effort  to maintain the high quality service we provide, we register only a limited  number of trainees in each batch. So taking up a spot and then canceling is not  appreciated and comes with a penalty.
                                <ol>
                                    <li>If membership is cancelled  10 days or more before the commencement of the programme, a refund will be  issued after deducting a cancellation fee of Rs 3,000. </li>
                                    <li>If membership is cancelled  1-9 days before the commencement of the programme, a refund will be issued  after deducting a cancellation fee of Rs 6,000. If membership is cancelled  after the commencement of the programme within the first 10 days, a refund will  be issued after deducting a cancellation fee of Rs 9,000. </li>
                                    <li>Any cancellation post 10  days of programme commencement will attract a penalty of the entire cost of the  programme.</li>
                                </ol>
                            </li>
                            <li>
                                <strong>DEFERMENT:</strong> For reasons  similar to cancellation, deferring/postponing your membership to a later date  comes with a penalty too.
                                <ol>
                                    <li>If membership is deferred 5  days or more before the commencement of the programme, a credit will be issued  under your name. This will register you for the subsequent quarter.</li>
                                    <li>If membership is deferred  1-4 days before the commencement of the programme, a refund will be issued  after deducting a deferment fee of Rs 3,000.</li>
                                    <li>If membership is deferred  after the commencement of the programme, a credit after deductions will be  issued under your name. The deductions include a deferment fee of Rs 6,000 and  the pro-rated cost of the time spent by the coach as part of the programme  duration. </li>
                                </ol>
                            </li>
                            <li>When you sign up for any of The Quad’s workshops, events, services, or programs, you agree to enter areas or spaces (online or offline) where photography, audio, and video recording may occur.<br/>
                            By signing up, you consent to interview(s), photography, audio recording, video recording and its/their release, publication, exhibition, or reproduction to be used for internal training and quality purposes, webcasts, promotional purposes, telecasts, advertising, inclusion on websites, or any other purpose by The Quad and its employees and representatives. You release the Quad, it’s employees, and each and all persons involved from any liability connected with the taking, recording, digitizing, or publication of interviews, photographs, computer images, video, and/or sound recordings.<br/>
                            You have been fully informed of your consent, waiver of liability, and release before signing up for any of The Quad’s services. If you would not like to be part of these recordings, photography, and/or screenshots, please write to hr@thequad.in or inform your coaches in writing of the same, and we will ensure that you are not part of the same.</li>
                        </ol>
                    </div>
                </div>
            </article>
            <Button style={{ margin: "0", marginBottom: "1vh" }} label="Go Back To Payment" fullWidth onClick={onBack} />
        </div>
    );
};

export default TermsAndConditions;
