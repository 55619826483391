import React from "react";

const VerticalContainer = (props) => {
    return (
        <div style={{display:"flex", flexDirection:"column", ...props.style}}>
            {props.children}
        </div>
    );
};

export default VerticalContainer;
