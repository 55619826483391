import React from "react";
import TextField from "@material-ui/core/TextField";

const TextFieldCustomised = (props) => {
    return <TextField 
        margin="normal" 
        variant="filled" 
        fullWidth 
        InputLabelProps={{shrink: true}}
        InputProps={{readOnly:props.readOnly || false}}
        {...props} 
    />;
};

export default TextFieldCustomised;