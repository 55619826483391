import React from "react";
import moment from "moment";



const round = (num) => {
    return Math.round(num * 100) / 100;
};



const PaymentReceipt = ({ orderData }) => {
    return (
        <div style={{backgroundColor:"#fff",fontFamily:"arial,sansSerif",color:"#1e1e1e",textAlign:"left",fontSize:"15px",margin:"0",lineHeight:"20px"}}>
            <table style={{border:"1px solid #dddddd",borderCollapse:"collapse",margin:"0 auto"}}>
                <thead style={{backgroundColor:"#ee4122",color:"#fff",fontWeight:"bold",fontSize:"20px"}}>
                    <tr style={{padding:"20px"}}>
                        <td colspan="6" align="center" style={{padding:"15px"}}>RECEIPT CUM TAX INVOICE</td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{padding:"20px",borderTop:"1px solid#dddddd"}}>
                        <td colspan="2" style={{padding:"20px",borderRight:"1px solid #dddddd"}}>
                            <img src="https://pay.thequad.in/images/the-quad-logo.png" alt="" />
                        </td>
                        <td colspan="4" style={{padding:"20px",fontSize:"14px",borderRight:"1px solid #dddddd"}}>
                            7/15, 1st Main Road, Seethammal Colony,<br />
                            Teynampet, Chennai - 600 018, State Code : 33<br />
                            <b>Web:  <a href="https://thequad.in" target="_blank" rel="noopener noreferrer">https://thequad.in</a>   |    Email: <a href="mailto:info@thequad.in" target="_blank" rel="noopener noreferrer">info@thequad.in</a> </b>
                        </td>
                    </tr>
                    <tr style={{padding:"20px",borderTop:"1px solid #dddddd"}}>
                        <td colspan="2" style={{padding:"20px",fontSize:"14px"}}>
                            <strong>GSTIN:  33AAACQ2643J1ZL</strong><br />
                            <strong>Service: </strong>{orderData.service}<br />
                            <strong>Location: </strong>{orderData.location}
                        </td>
                        <td colspan="3" style={{padding:"0 20px 0 60px",fontSize:"14px",borderRight:"1px solid #dddddd"}}>
                            <strong>Date: {moment().format("DD-MMM-YYYY")}</strong><br />
                            <strong>Invoice No: </strong>{orderData.invoiceId}<br />
                            <strong>Batch: </strong>{orderData.batch}
                        </td>
                    </tr>
                    <tr style={{padding:"20px",borderTop:"1px solid #dddddd"}}>
                        <td colspan="2" style={{padding:"20px",fontSize:"14px",borderRight:"1px solid #dddddd"}}>
                            <strong style={{fontSize:"22px"}}>{orderData.name}</strong><br />
                            {orderData.phoneNumber}<br />
                            <a href={"mailto:" + orderData.email} target="_blank" rel="noopener noreferrer">{orderData.email}</a>
                        </td>
                        <td colspan="44" style={{padding:"0 20px 0 60px",fontSize:"14px"}}>
                            <strong>Duration: </strong>{orderData.duration}<br />
                            <strong>Payment Mode: </strong>{orderData.paymentOption}<br />
                            <strong>Reason: </strong>{orderData.comments}
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr style={{padding:"20px",borderTop:"1px solid #dddddd",backgroundColor:"#f2f2f2"}}>
                        <th colspan="4" style={{padding:"20px",borderRight:"1px solid #dddddd"}}>Description of Service</th>
                        <th colspan="2" style={{borderRight:"1px solid #dddddd",padding:"0 0 0 50px"}}>Amount in INR</th>
                    </tr>
                    <tr style={{padding:"20px"}}>
                        <td colspan="4" style={{padding:"20px",borderRight:"1px solid #dddddd"}}>{orderData.service}</td>
                        <td colspan="2" style={{borderRight:"1px solid #dddddd",padding:"0 0 0 50px"}}>{round(orderData.amountInINR /118 * 100)}</td>
                    </tr>
                    <tr style={{padding:"20px"}}>
                        <td colspan="4" style={{padding:"20px",borderRight:"1px solid #dddddd"}}>
                            CGST 9%<br />
                            SGST 9%<br />
                            IGST 0%       
                        </td>
                        <td colspan="2" style={{borderRight:"1px solid #dddddd",padding:"0 0 0 50px"}}>
                            {round(orderData.amountInINR /118 * 9)}<br />
                            {round(orderData.amountInINR /118 * 9)}<br />
                            0.00
                        </td>
                    </tr>
                    <tr style={{padding:"20px",borderTop:"1px solid #dddddd",backgroundColor:"#f2f2f2"}}>
                        <td colspan="4" style={{padding:"20px",fontSize:"20px",fontWeight:"bold"}}> TOTAL:   </td>
                        <td colspan="2" style={{padding:"0 0 0 54px",fontSize:"20px",fontWeight:"bold",borderRight:"1px solid #dddddd"}}>
                            {orderData.amountInINR}
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr style={{padding:"20px",borderTop:"1px solid #dddddd"}}>
                        <td colspan="6" style={{padding:"20px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd"}}>
                            For<br />
                            <b>Quad Fitness Private Limited</b><br />
                            <p style={{fontSize:"12px"}}>(Computer Generated Invoice.Signature not Required)</p>
                        </td>
                    </tr>
                    <tr style={{padding:"20px"}}>
                        <th colspan="6" style={{padding:"15px",borderTop:"1px solid #dddddd",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center"}}>Amount of Tax Subject to Reverse Charge 0</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PaymentReceipt;
