import React from "react";

const HorizontalContainer = (props) => {
    return (
        <div style={{width:"100%", display:"flex", flexDirection:"row", ...props.style}}>
            {props.children}
        </div>
    );
};

export default HorizontalContainer;

