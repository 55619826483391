import React from "react";
import "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const DatePicker = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker 
                margin="normal" 
                variant="filled" 
                format="dd-MMM-yyyy"
                fullWidth 
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly:props.readOnly || false}}
                {...props} 
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
